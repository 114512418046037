import { getFolderStructure } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useUploadCloudinaryFile } from '../../../../../../../../../../../../../../../../api/cloudinary/routes';
import { productAtoms } from '../../../../../../../../../../../../../../product.atoms';
import { SkuLevelCloudinaryFileType } from '../../../../../../../../../../../../../../../../api/products/products.types';
import { SkuAssetUploadFileData } from '../../../../../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { getTransformProductAssetFileName } from '../../../../../../../../../../../../helpers/get-transform-product-asset-file-name';

export const useHandleUploadCloudinaryAsset = () => {
  const product = useRecoilValue(productAtoms.product);

  const { onUploadCloudinaryFile } = useUploadCloudinaryFile();

  const handleUpload = async (props: {
    fileData: SkuAssetUploadFileData;
    fileType: SkuLevelCloudinaryFileType;
    sku: string;
  }) => {
    const { fileData, fileType, sku } = props;

    const folder = getFolderStructure<'products'>({
      directory: 'products',
      fileType,
      identifiers: {
        productId: product.id,
        sku,
      },
    });

    const { transformFileName } = getTransformProductAssetFileName({
      fileType,
      sku,
    });

    const publicId = transformFileName({ fileName: fileData.fileName });

    return onUploadCloudinaryFile({
      file: fileData?.fileBlob,
      folder,
      publicId,
      resourceType: 'image',
    });
  };

  return handleUpload;
};
