import { useRecoilValue } from 'recoil';
import { useDeleteCloudinaryFile } from '../../../../../../../../../../../../api/cloudinary/routes';
import { deleteBlob } from '../../../../../../../../../../../../helpers/firebase';
import { useNotification } from '../../../../../../../../../../../../hooks/useNotification';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { bulkUploadModalAtoms } from '../../../../bulk-upload-modal.atoms';
import { useUpdateSkuAssets } from '../../../../../../../../../../../../api/products/routes/update-sku-assets';
import { useHandleBulkUploadSavePropsDef } from './use-handle-bulk-upload-save.types';
import { useHandleBulkUploadAssets } from './hooks/use-handle-bulk-upload-assets';

export const useHandleBulkUploadSave = (
  props: useHandleBulkUploadSavePropsDef,
) => {
  const { handleCloseModal } = props;

  const product = useRecoilValue(productAtoms.product);
  const filesToDeleteCloudinary = useRecoilValue(
    bulkUploadModalAtoms.filesToDeleteCloudinary,
  );
  const filesToDeleteFirebase = useRecoilValue(
    bulkUploadModalAtoms.filesToDeleteFirebase,
  );

  const {
    notifyError,
    onInterceptProcessingRequest,
    onInterceptRequest,
    processing,
  } = useNotification();

  const { onDeleteFile: onDeleteCloudinaryFile } = useDeleteCloudinaryFile();

  const { onUpdateSkuAssets } = useUpdateSkuAssets();

  const handleBulkUploadAssets = useHandleBulkUploadAssets();

  const handleSave = async () => {
    // trigger full-page processing indicator
    await onInterceptRequest(async () => {
      // trigger processing state change to disable save button
      return onInterceptProcessingRequest(async () => {
        const { numUploaded, skuAssets } = await handleBulkUploadAssets();

        const numDeleted =
          filesToDeleteCloudinary.length + filesToDeleteFirebase.length;

        await onUpdateSkuAssets({
          numDeleted,
          numUploaded,
          productId: product.id,
          skuAssets,
        });

        await Promise.all(
          filesToDeleteCloudinary.map((file) => {
            // TODO - consider adding limiter to this logic to prevent too many requests to cloudinary api
            // ? https://support.cloudinary.com/hc/en-us/articles/202520892-Are-Cloudinary-APIs-rate-limited
            return onDeleteCloudinaryFile(file);
          }),
        );

        await Promise.all(
          filesToDeleteFirebase.map(({ fileUrl }) => {
            return deleteBlob({ fileUrl, onError: notifyError });
          }),
        );
      });
    });

    await handleCloseModal({ updateOnClose: true });
  };

  return { handleSave, processing };
};
